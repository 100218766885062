import React from 'react'
import { graphql } from 'gatsby'

import { mapEdgesToNodes } from '../utils/helpers'
import WorkFeatureList from '../components/work-feature-list'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'

import styles from './work.module.css'

export const query = graphql`
  query WorkPageQuery {
    posts: allSanityPost(
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          categories {
            _id
            title
          }
          mainImage {
            ...SanityImage
            alt
          }
          isJournalEntry
          showCategories
          title
          slug {
            current
          }
          postAtRoot
          excerpt
          headerBgColor {
            hex
          }
          headerLinkColor {
            hex
          }
          headerTitleColor {
            hex
          }
          documentColor {
            hex
          }
          headerIframe
          forceFullWidth
          headerImage {
            image {
              asset {
                _id
              }
            }
            width
            alt
          }
          headerLinks {
            url
            title
          }
        }
      }
    }
  }
`

const WorkPage = (props) => {
  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const postNodes = data && data.posts && mapEdgesToNodes(data.posts)

  return (
    <Layout>
      <SEO title="Work" description="The official blog of Jarren Rocks." />

      <Container >

        <h1 className={styles.title}>Work</h1>
 
        {postNodes && postNodes.length > 0 && <WorkFeatureList nodes={postNodes} />}
      </Container>

    </Layout>
  )
}

export default WorkPage
